// File to define common Gatsby browser & ssr function(s)
import React from 'react';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { AuthProvider } from '@vp/digital-auth-lib';
import {
  RuntimeContextProvider,
  getClientEnvironment,
} from '@vp/digital-environment-lib';
import { UrlContextProvider } from '@vp/digital-url-context-lib';
import {
  RootLayout,
  HtmlAttributes,
  MainLayout,
} from '@vp/digital-site-layout-lib';

import { StyleKeys, ScriptKeys } from './src/swanKeys';
import { BookendsWrapper } from '@vp/digital-bookends-lib';
import { Helmet } from 'react-helmet';

const ProductPageWrapper = ({ pageContext, location, children }) => {
  const { locale, directAccessClientId, trackingConfiguration } = pageContext;
  return (
    <LocaleProvider rawLocaleIdentifier={locale}>
      <RuntimeContextProvider getEnvironment={getClientEnvironment}>
        <AuthProvider directAccessClientId={directAccessClientId}>
          <UrlContextProvider location={location}>
            <RootLayout swanStyleKeys={StyleKeys} swanScriptKeys={ScriptKeys}>
              <HtmlAttributes />
              <BookendsWrapper
                trackingConfiguration={trackingConfiguration}
                locale={locale}
                renderMetaWith={Helmet}
              >
                <MainLayout>{children}</MainLayout>
              </BookendsWrapper>
            </RootLayout>
          </UrlContextProvider>
        </AuthProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { wrap },
  } = props;
  return wrap ? (
    <ProductPageWrapper {...props}>{element}</ProductPageWrapper>
  ) : (
    element
  );
};
